let menu = document.getElementById("js-menu");
let spNab = document.querySelector(".l-header__sp-nav");

menu.addEventListener("click", () => {
  spNab.classList.toggle("is-active-menu");
  if (spNab.classList.contains("is-active-menu")) {
    document.getElementById("js-menu").innerHTML = `  
    <svg xmlns="http://www.w3.org/2000/svg" width="21.243" height="21.242" viewBox="0 0 21.243 21.242">
    <g id="group_10808" data-name="group 10808" transform="translate(8371.621 570.621)">
        <line id="line_74" data-name="line 74" x2="17" y2="17" transform="translate(-8369.5 -568.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3" />
        <line id="line_75" data-name="line 75" x1="17" y2="17" transform="translate(-8369.5 -568.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3" />
    </g>
    </svg>`;
  } else {
    document.getElementById("js-menu").innerHTML = `  
    <svg id="group_11594" data-name="group 11594" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21.303" height="17.245" viewBox="0 0 21.303 17.245">
    <defs>
        <clipPath id="clip-path">
            <rect id="box_4490" data-name="box 4490" width="21.303" height="17.245" fill="#fff" />
        </clipPath>
    </defs>
    <g id="group_10803" data-name="group 10803" clip-path="url(#clip-path)">
        <path id="path_14" data-name="path 14" d="M2.029,1.927a2.029,2.029,0,1,0,0,4.058H3.583a3.983,3.983,0,0,0,6.883,0h8.808a2.029,2.029,0,0,0,0-4.058H10.437a3.983,3.983,0,0,0-6.823,0ZM5,3.982A2.029,2.029,0,1,1,7.025,6.01,2.029,2.029,0,0,1,5,3.982" fill="#fff" fill-rule="evenodd" />
        <path id="path_15" data-name="path 15" d="M2.029,54.763a2.029,2.029,0,0,0,0,4.058h8.807a3.983,3.983,0,0,0,6.883,0h1.555a2.029,2.029,0,1,0,0-4.058H17.69a3.983,3.983,0,0,0-6.823,0Zm10.22,2.054a2.029,2.029,0,1,1,2.029,2.029,2.029,2.029,0,0,1-2.029-2.029" transform="translate(0 -43.553)" fill="#fff" fill-rule="evenodd" />
    </g>
</svg>
`;
  }
});

document.addEventListener("DOMContentLoaded", function () {
  let clipboard = new ClipboardJS(".share-url");
  let clipboardBtn = document.querySelector(".share-url");

  clipboard.on("success", function (e) {
    function resetAnimation() {
      clipboardBtn.classList.remove("animate");
      void clipboardBtn.offsetWidth;
      clipboardBtn.classList.add("animate");
    }
    resetAnimation();
  });
});
